import React from 'react';
import {Link} from "react-router-dom";

export default function NavBar(){

     return (
	  <div>
		  <div className="header-top" style={{"padding":"3px 0px","background-color":"GREEN"}}>
			<div className="auto-container">
			  <div className="clearfix">
				<div id="latestupdate" style={{"color":"white","font-size":"15px","font-weight":"600"}}>
				  <marquee onmouseover="this.stop();" onmouseout="this.start();">
					<strong>Latest Update :  &nbsp;  <i className="fa fa-download" style={{"font-size":"16px"}}><a style={{"color":"white"}} href="download/Financial_Results/Annual-Report-2024.pdf" target="_Blank"> Annual Report 2024 </a></i>
					  &nbsp; | <i className="fa fa-download" style={{"font-size":"16px"}}><a style={{"color":"white"}} href="download/notice/Notice-of-13th-Agm.pdf" target="_Blank"> Notice of 13th Agm</a></i>
					  &nbsp; | <i className="fa fa-download" style={{"font-size":"16px"}}><a style={{"color":"white"}} href="download/notice/Attendence_Slip.pdf" target="_Blank"> Attendence Slip</a></i>
					</strong></marquee>
				</div>
			  </div>
			</div>
		  </div>
		  <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 px-4 px-lg-5">
				<Link to="/" className="navbar-brand d-flex align-items-center">
				  <h2 className="m-0 text-primary"><img className="img-fluid me-2" src="assets/img/logo.PNG" alt="logo" style={{"width":"150px"}} /></h2>
				</Link>
				<button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
				  <span className="navbar-toggler-icon" />
				</button>
				<div className="collapse navbar-collapse" id="navbarCollapse">
				  <div className="navbar-nav ms-auto py-4 py-lg-0">
					<Link to="/" className="nav-item nav-link" >Home</Link>
					<Link to="/about" className="nav-item nav-link">About</Link>
					<div className="nav-item dropdown">
					<Link to="/investor-relation" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Investor Relation</Link>
                    <div className="dropdown-menu shadow-sm m-0">
                        <Link to="#" className="dropdown-item">Financial Results</Link>
                        <Link to="#" className="dropdown-item">Regulation 30</Link>
                        <Link to="#" className="dropdown-item">Board Of Director's & Board Meetings</Link>
                        <Link to="#" className="dropdown-item">Shareholding Pattern</Link>
                        <Link to="#" className="dropdown-item">Committees Of Board</Link>
                        <Link to="#" className="dropdown-item">Investor Information</Link>
                        <Link to="#" className="dropdown-item">General Meetings & Postal Ballot</Link>
                        <Link to="#" className="dropdown-item">Annual Return</Link>
                        <Link to="#" className="dropdown-item">Annual Reports</Link>
                        <Link to="#" className="dropdown-item">Codes & Policies</Link>
                        <Link to="#" className="dropdown-item">Prospectus</Link>
                        <Link to="#" className="dropdown-item">Press Release & Announcement</Link>
                        <Link to="#" className="dropdown-item">Unclaimed Dividend</Link>
                        <Link to="#" className="dropdown-item">Scheme of Amalgamation</Link>
						</div>
					</div>
				    <div className="nav-item dropdown">
					<Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Corporate Governance</Link>
                    <div className="dropdown-menu shadow-sm m-0">
                        <Link to="#" className="dropdown-item">Disclosure Under Regulation</Link>
                        <Link to="#" className="dropdown-item">SEBI (SAST) Regulations, 2011</Link>
                        <Link to="#" className="dropdown-item">Annual Secretarial Compliance Report</Link>
						</div>
					</div>
				
				
					<Link to="/contact" className="nav-item nav-link">Contact</Link>
				   
				  </div>
				  
				</div>
			  </nav>
		</div>
    );
}